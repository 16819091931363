import React from 'react';
import css from './Footer.module.scss';

export class Footer extends React.Component {
    render() {
        return <footer className={ css.container }>
            <div className={css.link}>Сайт разработан <a href="https://www.ignashevich.dev/" target="_blank">ignashevich.dev</a></div>
            <div className={ css.logo }>© 2004—2025 tulpan-mart.by</div>
        </footer>
    }
}
