import React from 'react';
import { Contacts } from './Contacts';
import { Delivery } from './Delivery';
import css from './Main.module.scss';
import { About } from './About';
import { ContentContainer } from '../../shared/ContentContainer';
import { Button } from '@mui/material';
import { HowToOrder } from './HowToOrder/HowToOrder';
import { Quality } from './Quality';

export default function Main() {
    return (
        <div>
            <div className={ css.mainHeader }>
                <ContentContainer className={ css.mainHeaderContainer }>
                    <div className={ css.mainHeaderText }>
                        <h1 className={ css.headline }>
                            Тюльпаны оптом к 8 марта 2025 от производителя!
                        </h1>
                        <h2 className={ css.headline2 }>
                            На протяжении 20-ти лет наше хозяйство успешно занимается выгонкой тюльпанов к
                            Международному женскому дню 8 Марта и дню св.
                            Валентина и последующей оптовой продажей.
                            <br/>
                            <Button href="/tulips" size="large" style={ { marginTop: 12 } } variant="contained" color="secondary">Сделать заказ</Button>
                        </h2>
                    </div>
                </ContentContainer>
            </div>
            <About/>
            <HowToOrder/>
            <Contacts/>
            <Delivery/>
            <Quality/>
        </div>
    );
}
